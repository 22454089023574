import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { NotificationManager } from "react-notifications";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { DownloadExcel } from "react-excel-export";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import * as XLSX from "xlsx";

import { Row, Col, Card, Table } from "react-bootstrap";
import { allUser, url } from "../../../services/api_function";
import { Link } from "react-router-dom";
import { COLUMNS } from "../../components/table/FilteringTable/Columns";
import MOCK_DATA from "../../components/table/FilteringTable/MOCK_DATA_2.json";

export const AllUser = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [approveLoading, setapproveLoading] = useState({});
  const [rejectLoading, setrejectLoading] = useState({});
  const [statusFilter, setStatusFilter] = useState("");
  const pageSize = 100;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const result = await allUser(
          currentPage,
          { searchQuery: search,  status: statusFilter },
        
          token
        );

        if (result.data.length == 0) {
          throw new Error("No data found");
        }
        setApiData(result.data);
        setFilteredData(result.data);
        const total = result.totalUser;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
        if (result.status == 404) {
          localStorage.removeItem("userDetails");
          window.location.href = "/logout";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search,statusFilter]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleEditClick = (phoneNumber) => {
    console.log("Edit Clicked for phoneNumber:", phoneNumber);
  };
  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    } else {
      return "-";
    }
  };
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => apiData, [apiData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleApproval = async (from) => {
    setapproveLoading({ ...approveLoading, [from]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/user-block", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tokenId: from,
        isblocked: true,
      }),
    });

    if (apiResponse.status == 200) {
      const errorMessage = await apiResponse.json();
      setapproveLoading(false);
      NotificationManager.success(errorMessage.message);

      const result = await allUser(currentPage, { searchQuery: search }, token);
      if (result.data.length == 0) {
        throw new Error("No data found");
      }
      setApiData(result.data);
      setFilteredData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        setapproveLoading(false);
        NotificationManager.error(errorMessage.message);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setapproveLoading(false);
      }
    }
  };

  const handleReject = async (from) => {
    setrejectLoading({ ...rejectLoading, [from]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/user-block", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tokenId: from,
        isblocked: false,
      }),
    });
    if (apiResponse.status == 200) {
      const errorMessage = await apiResponse.json();
      NotificationManager.success(errorMessage.message);
      setrejectLoading(false);
      const result = await allUser(currentPage, { searchQuery: search }, token);

      if (result.data.length == 0) {
        throw new Error("No data found");
      }
      setApiData(result.data);
      setFilteredData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        NotificationManager.error(errorMessage.message);
        setrejectLoading(false);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setrejectLoading(false);
      }
    }
  };

  const handleStatusChange = async (e) => {
    const selectedStatus = e.target.value;
    setStatusFilter(selectedStatus);
    setCurrentPage(1);
  };
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                All Users
              </Card.Title>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="true"
                  onChange={handleStatusChange}
              
                />
                <label class="form-check-label" for="inlineRadio1">
                  Active
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="false"
                  onChange={handleStatusChange}
               
                />
                <label class="form-check-label" for="inlineRadio2">
                  Inactive
                </label>
              </div>
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <div className="input-group" style={{ maxWidth: "300px" }}></div>
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>
                    <th>
                      <strong>User Name</strong>
                    </th>
                    <th>
                      <strong>Phone</strong>
                    </th>
                    <th>
                  
                      <strong> UserID</strong>
                    </th>
                    <th>
                      <strong>Sponsor ID</strong>
                    </th>
                    <th>
                      <strong>Sponsor Name</strong>
                    </th>
                    <th>
                      <strong>Promoter ID</strong>
                    </th>
                    <th>
                      <strong>Position </strong>
                    </th>
                    <th>
                      <strong>TG Stake</strong>
                    </th>
                    <th>
                      <strong>Team Business</strong>
                    </th>
                    <th>
                      <strong>Total Team</strong>
                    </th>
                    <th>
                      <strong>Total Direct</strong>
                    </th>
                    <th>
                      <strong>Demat Reward (INR)</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                    <th>
                      <strong>Block</strong>
                    </th>
                    <th>
                      <strong>Unblock</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((user, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{user.username}</td>
                        <td>{user.mobile}</td>
                        <td>{user.userId}</td>
                        <td className="text-center">{user.sponsorId}</td>
                        <td>{user.sponsorName}</td>
                        <td>{user.promoterId}</td>
                        <td>{user.position}</td>
                        <td className="text-center">
                          {(user.totalStake ).toFixed(2)}
                        </td>
                        <td className="text-center">
                          {(user.levelteambusiness).toFixed(2)}
                        </td>
                        <td className="text-center">{user.totalteam}</td>
                        <td className="text-center">{user.totaldirects}</td>
                        <td className="text-center">{user.dematRewardinr}</td>
                        <td>{formatTimestamp(user?.createdAt)}</td>
                        <td>
                          {!user.isblocked ? (
                            <button
                              style={{
                                cursor: "pointer",
                                padding: "5px 10px",
                                color: "white",
                                backgroundColor: "red",
                                fontWeight: "bold",
                                border: "none",
                                borderRadius: "4px",
                                outline: "none",
                              }}
                              onClick={() => handleApproval(user.uniqueId)}
                              disabled={approveLoading[user.uniqueId]}
                            >
                              {approveLoading[user.uniqueId]
                                ? "Blocking..."
                                : "Block"}
                            </button>
                          ) : (
                            <span
                              style={{
                                padding: "5px 10px",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            >
                              Blocked
                            </span>
                          )}
                        </td>

                        <td>
                          {user.isblocked ? (
                            <button
                              style={{
                                cursor: "pointer",
                                padding: "5px 10px",
                                color: "white",
                                backgroundColor: "green",
                                fontWeight: "bold",
                                border: "none",
                                borderRadius: "4px",
                                outline: "none",
                              }}
                              onClick={() => handleReject(user.uniqueId)}
                              disabled={rejectLoading[user.uniqueId]}
                            >
                              {rejectLoading[user.uniqueId]
                                ? "Unblocking..."
                                : "Unblock"}
                            </button>
                          ) : (
                            <span
                              style={{
                                padding: "5px 10px",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              Unblocked
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="bg-black text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllUser;
