import React, { Fragment, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { Kyc_user, urlFetch, url } from "../../../services/api_function";
import { NotificationManager } from "react-notifications";

const Kycuser = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [search, setSearch] = useState("");
  const [approveLoading, setapproveLoading] = useState({});
  const [rejectLoading, setrejectLoading] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [imgname, setImgName] = useState("");
  const pageSize = 100;

  const url1 = "https://api.buytgcoin.com/api/kyc"
  useEffect(() => {
    const fetchData = async () => {
      const userDetails = localStorage.getItem("userDetails");
      const parsedDetails = JSON.parse(userDetails);
      const token = parsedDetails.token;
      try {
        const result = await Kyc_user(
          currentPage,
          {
            searchQuery: search,
          },
          token
        );
        console.log(result, "result");
        setApiData(result.data);
        const total = result.totalUser;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleApproval = async (userId) => {
    setapproveLoading({ ...approveLoading, [userId]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/admin-kyc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId,
        isApproved: true,
        isRejected: false,
      }),
    });

    if (apiResponse.status == 200) {
      const responseData = await apiResponse.json();
      NotificationManager.success(responseData.message);
      const result = await Kyc_user(
        currentPage,
        {
          searchQuery: search,
        },
        token
      );
      setApiData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
      setapproveLoading(false);
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        setapproveLoading(false);
        NotificationManager.error(errorMessage.message);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setapproveLoading(false);
      }
    }
  };

  const handleReject = async (userId) => {
    setrejectLoading({ ...rejectLoading, [userId]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(url + "/admin-kyc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId,
        isApproved: false,
        isRejected: true,
      }),
    });
    if (apiResponse.status == 200) {
      const response = await apiResponse.json();
      NotificationManager.success(response.message);
      const result = await Kyc_user(
        currentPage,
        {
          searchQuery: search,
        },
        token
      );
      setApiData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
      setrejectLoading(false);
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        NotificationManager.error(errorMessage.message);
        setrejectLoading(false);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setrejectLoading(false);
      }
    }
  };

  const closeModal = () => {
    setSelectedUserId(null); // Reset the selected user ID
    setShowModal(false); // Close the modal
  };
  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setImgName(user.upiQr ? user.upiQr.slice(user.upiQr.lastIndexOf("/")) : "");
    setShowModal(true);
  };
 
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {/* <div className="input-group" style={{ maxWidth: "300px" }}>
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Search here..."
                onChange={handleSearch}
              />
            </div> */}
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                KYC User List
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>

                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>User Id</strong>
                    </th>
                    <th>
                      <strong>UPI NO </strong>
                    </th>
                    <th>
                      <strong>UPI ID</strong>
                    </th>
                    <th>
                      <strong>Bank Name</strong>
                    </th>
                    <th>
                      <strong> IFCS Code </strong>
                    </th>
                    <th>
                      <strong>Account NO</strong>
                    </th>
                    <th>
                      <strong>Qr Code</strong>
                    </th>
                    <th>
                      <strong>Approve</strong>
                    </th>
                    <th>
                      <strong>Reject</strong>
                    </th>
                    <th>
                      <strong>Date & Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.map((user, index) => (
                    <tr>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>{user.username}</td>
                      <td>{user.userId}</td>
                      <td>{user.upinumber}</td>
                      <td>{user.upiId}</td>
                      <td>{user.bankName}</td>
                      <td>{user.ifscCode}</td>
                      <td>{user.accountNumber}</td>
                      <td>
                        <button
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "white",
                            backgroundColor: "green",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          onClick={() => handleViewDetails(user)}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "white",
                            backgroundColor: "green",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          onClick={() => handleApproval(user.userId)}
                          disabled={approveLoading[user.userId]}
                        >
                          {approveLoading[user.userId]
                            ? "Approving..."
                            : "Approve"}
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "white",
                            backgroundColor: "red",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          onClick={() => handleReject(user.userId)}
                          disabled={rejectLoading[user.userId]}
                        >
                          {rejectLoading[user.userId]
                            ? "Rejecting..."
                            : "Reject"}
                        </button>
                      </td>
                      <td>{formatTimestamp(user.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal} size="sm">
        <Modal.Body className="text-center">
          {selectedUser && (
            <img
              src={url1 + "/" +imgname}
              alt="QR Code"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Kycuser;
