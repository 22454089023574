import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";

import { NotificationManager } from "react-notifications";
import { Activate_data, urlFetch } from "../../../services/api_function";
import { Link } from "react-router-dom";
import { COLUMNS } from "../table/FilteringTable/Columns";
import MOCK_DATA from "../table/FilteringTable/MOCK_DATA_2.json";
//import './table.css';

export const Approveusd = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [approveLoading, setapproveLoading] = useState({});
  const [rejectLoading, setrejectLoading] = useState({});
  const [search, setSearch] = useState("");
  const pageSize = 100;
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const fetchData = async () => {
      try {
        const result = await Activate_data(currentPage, token);
        setApiData(result.data);
        const total = result.totalUser;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;
  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };
  const handlesearch = async (e) => {
    const query = e.target.value.toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const admin = "TG1234567";
  const handleApproval = async (to, from) => {
    setapproveLoading({ ...approveLoading, [from]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(urlFetch, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        adminId: admin,
        tokenId: from,
        isapprove: true,
        isrejected: false,
      }),
    });

    if (apiResponse == 200) {
      const response = await apiResponse.json();
      NotificationManager.success(response.message);
      const responseData = await apiResponse.json();
      NotificationManager.success(responseData.message);
      const result = await Activate_data(currentPage, token);
      setApiData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } else {
      const errorMessage = await apiResponse.text();
      NotificationManager.error(errorMessage);
    }
  };

  const handleReject = async (to, from) => {
    setrejectLoading({ ...rejectLoading, [from]: true });
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    const apiResponse = await fetch(urlFetch, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        adminId: admin,
        tokenId: from,
        isapprove: false,
        isrejected: true,
      }),
    });

    if (apiResponse.status == 200) {
      const responseData = await apiResponse.json();
      NotificationManager.success(responseData.message);
      const result = await Activate_data(currentPage, token);
      setApiData(result.data);
      const total = result.totalUser;
      const pages = Math.ceil(total / pageSize);
      setTotalPages(pages > 0 ? pages : 1);
    } else {
      if (apiResponse.status == 400) {
        const errorMessage = await apiResponse.json();
        setapproveLoading(false);
        NotificationManager.error(errorMessage.message);
      } else {
        NotificationManager.error("Failed to send transaction data");
        setapproveLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Approve USDT
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>

                    <th>
                      <strong>From</strong>
                    </th>

                    <th>
                      <strong>To</strong>
                    </th>

                    <th>
                      <strong>Amount</strong>
                    </th>
                    <th>
                      <strong>Currency</strong>
                    </th>
                    <th>
                      <strong>Approve</strong>
                    </th>
                    <th>
                      <strong>Reject</strong>
                    </th>
                    <th>
                      <strong> Transaction ID </strong>
                    </th>
                    <th>
                      <strong>Date</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiData?.map((user, index) => (
                    <tr>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>{user.from}</td>
                      <td>{user.to}</td>

                      <td>
                        {(user.type == "INR"
                          ? user.amount
                          : user.amount / 1e18
                        ).toFixed(2)}
                      </td>
                      <td>{user.type}</td>
                      <td>
                        <button
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "white",
                            backgroundColor: "green",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          onClick={() => handleApproval(user.to, user.uniqueId)}
                          disabled={approveLoading[user.uniqueId]}
                        >
                          {approveLoading[user.uniqueId]
                            ? "Approving..."
                            : "Approve"}
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            color: "white",
                            backgroundColor: "red",
                            fontWeight: "bold",
                            border: "none",
                            borderRadius: "4px",
                            outline: "none",
                          }}
                          onClick={() => handleReject(user.to, user.uniqueId)}
                          disabled={rejectLoading[user.uniqueId]}
                        >
                          {rejectLoading[user.uniqueId]
                            ? "Rejecting..."
                            : "Reject"}
                        </button>
                      </td>
                      <td>
                        <a
                          href={`https://bscscan.com/tx/${user.txid}`}
                          className="text-white"
                          target="_blank"
                        >
                          {user.txid.slice(0, 10)}... {user.txid.slice(50)}
                        </a>
                      </td>
                      <td>{formatTimestamp(user.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                      color: "black",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Approveusd;
