import React, { useState, useEffect, useMemo, useRef } from "react";
// import CryptoJS from "crypto-js";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Col, Row, Card, Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
// import Sidebar from "./Sidebar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { colors } from "@mui/material";
import axios from "axios";
import { url } from "../../../services/api_function";

const UserProfile = (props) => {
  const location = useLocation();
  const data = new URLSearchParams(location.search).get("userId");
  const [user, setUser] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [currentPage4, setCurrentPage4] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  const [totalPages3, setTotalPages3] = useState(1);
  const [totalPages4, setTotalPages4] = useState(1);
  const [userData, setUserData] = useState();
  const [apidata, setapiData] = useState([]);
  const [apidata1, setapiData1] = useState();
  const [userData1, setUserData1] = useState();
  const [userData2, setUserData2] = useState();
  const [addresh, setaddresh] = useState();
  const [bank, setBank] = useState();
  const [upi, setupi] = useState();
  const [business, setbusiness] = useState();
  const [wallet, setwallet] = useState();
  const [showUPI, setShowUPI] = useState(false);

  // const userId = useMemo(() => user, [user]);
  const userId = "TG2755477";
  const pageSize = 40;

  const fetchData = async () => {
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    try {
      const response = await axios.post(
        url + "/user-info-admin",
        { userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.status == 200 && !response.error) {
        setapiData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(apidata, "apidat");
  //   useEffect(() => {
  //

  //     login_data(userId, currentPage2)
  //       .then((response) => {
  //         if (response.data) {
  //           setUserData2(response.data);
  //           const total = response.totalData;
  //           const pages = Math.ceil(total / pageSize);
  //           setTotalPages2(pages > 0 ? pages : 1);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [userId, currentPage, currentPage2]);

  //   useEffect(() => {
  //     recive_data(userId, currentPage1)
  //       .then((response) => {
  //         if (response.data) {
  //           setUserData1(response.data);
  //           const total = response.totaldata;
  //           const pages = Math.ceil(total / pageSize);

  //           setTotalPages1(pages > 0 ? pages : 1);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [currentPage1, userId]);

  //   useEffect(() => {
  //     loginBusseness_data(userId, currentPage3)
  //       .then((response) => {
  //         if (response.data) {
  //           setapiData1(response.data);

  //           const total = response.totaldata;
  //           const pages = Math.ceil(total / pageSize);
  //           setTotalPages3(pages > 0 ? pages : 1);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [currentPage3, userId]);

  //   useEffect(() => {
  //     addresh_data(userId)
  //       .then((response) => {
  //         if (response.data) {
  //           //  console.log(response, "::::::::");
  //           setaddresh(response.data);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [userId]);

  //   useEffect(() => {
  //     bank_data(userId)
  //       .then((response) => {
  //         if (response) {
  //           // console.log(response.data);
  //           setBank(response.data.bankData);
  //           setupi(response.data.upiData);
  //           setbusiness(response.data.business);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [userId]);

  //   useEffect(() => {
  //     wallet_data(userId, currentPage3)
  //       .then((response) => {
  //         if (response.data) {
  //           setwallet(response.data);
  //           const total = response.totaldata;
  //           const pages = Math.ceil(total / pageSize);
  //           setTotalPages4(pages > 0 ? pages : 1);
  //         } else {
  //           console.error("Invalid response format:", response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching team data:", error);
  //       });
  //   }, [currentPage3, userId]);

  //   const handleNextPage = () => {
  //     setCurrentPage((prevPage) =>
  //       prevPage < totalPages ? prevPage + 1 : prevPage
  //     );
  //   };

  //   const handlePreviousPage = () => {
  //     setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  //   };

  //   const handleNextPage1 = () => {
  //     setCurrentPage1((prevPage) =>
  //       prevPage < totalPages1 ? prevPage + 1 : prevPage
  //     );
  //   };

  //   const handlePreviousPage1 = () => {
  //     setCurrentPage1((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  //   };

  //   const handleNextPage2 = () => {
  //     setCurrentPage2((prevPage) =>
  //       prevPage < totalPages2 ? prevPage + 1 : prevPage
  //     );
  //   };

  //   const handlePreviousPage2 = () => {
  //     setCurrentPage2((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  //   };

  //   const handleNextPage3 = () => {
  //     setCurrentPage2((prevPage) =>
  //       prevPage < totalPages3 ? prevPage + 1 : prevPage
  //     );
  //   };

  //   const handlePreviousPage3 = () => {
  //     setCurrentPage2((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  //   };

  //   const handleNextPage4 = () => {
  //     setCurrentPage4((prevPage) =>
  //       prevPage < totalPages4 ? prevPage + 1 : prevPage
  //     );
  //   };

  //   const handlePreviousPage4 = () => {
  //     setCurrentPage4((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  //   };

  //   const handleSwitchChange = (event) => {
  //     setShowUPI(event.target.checked);
  //   };
  return (
    <>
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel page">
          <div class="content-wrapper">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="card" style={{}}>
                  <div className="card-body">
                    <div className="row align-items-center justify-content-between">
                      <h4 className="page_head"></h4>
                    </div>

                    <div>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Name:
                            </label>
                            <input
                            style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.username || ""}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            {/* <label style={{ paddingRight: "10px" }}>
                              Email:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                            
                              placeholder=""
                              readOnly
                              value={apidata?.data?.email || ""}
                            /> */}
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Number:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              value={apidata?.data?.mobile || ""}
                              readOnly
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            {/* <label style={{ paddingRight: "10px" }}>
                              User Id:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.userId || ""}
                            /> */}
                          </div>
                        </div>
                        <div class="row mt-5 ">
                          <div class="col-lg-3 col-sm-12">
                          <label style={{ paddingRight: "10px" }}>
                              Email:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                            
                              placeholder=""
                              readOnly
                              value={apidata?.data?.email || ""}
                            />
                            {/* <label style={{ paddingRight: "10px" }}>
                              PromotorID:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.promoterId || ""}
                            /> */}
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Sponser Name:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.sponsorName || ""}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Team:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.totalteam || ""}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Level Business:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.levelteambusiness || 0}
                            />
                          </div>
                        </div>
                        <div class="row mt-5 ">
                          <div class="col-lg-3 col-sm-12">
                          <label style={{ paddingRight: "10px" }}>
                              Number:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              value={apidata?.data?.mobile || ""}
                              readOnly
                            />
                            {/* <label style={{ paddingRight: "10px" }}>
                              Team Reward
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.totalReward || 0}
                            /> */}
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Stake Coin
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.stakeWallet || 0}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Total Withdraw:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.totalWithdraw || 0}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Dirct Reward:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.directReward || 0}
                            />
                          </div>
                        </div>
                        <div class="row mt-5 ">
                          <div class="col-lg-3 col-sm-12">
                          <label style={{ paddingRight: "10px" }}>
                              User Id:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.userId || ""}
                            />
                            {/* <label style={{ paddingRight: "10px" }}>
                              Left Team Business
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.leftteambusiness || 0}
                            /> */}
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Right Team Business
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.rightteambusiness || 0}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Topup Wallet
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              readOnly
                              value={Number(
                                apidata?.data?.tgBalance ?? 0
                              ).toFixed(2)}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Reward Wallet
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.rewardWallet || 0}
                            />
                          </div>
                        </div>
                        <div class="row mt-5 ">
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              PromotorID:
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.promoterId || ""}
                            />
                            {/* <label style={{ paddingRight: "10px" }}>
                           Holding Wallet
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.lockedWallet || 0}
                            /> */}
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                           Locked Wallet
                            </label>
                            <input
                              style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.stakeWallet || 0}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Topup Wallet
                            </label>
                            <input
                              style={{border:"none"}}
                              type="number"
                              placeholder=""
                              readOnly
                              value={Number(
                                apidata?.data?.tgBalance ?? 0
                              ).toFixed(2)}
                            />
                          </div>
                          <div class="col-lg-3 col-sm-12">
                            <label style={{ paddingRight: "10px" }}>
                              Reward Wallet
                            </label>
                            <input
                             style={{border:"none"}}
                              type="text"
                              placeholder=""
                              readOnly
                              value={apidata?.data?.rewardWallet || 0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3 "
              justify
            >
              <Tab eventKey="home" title="Send">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>

                              <th>
                                <strong>TO</strong>
                              </th>
                              <th>
                                <strong>Tokens ID</strong>
                              </th>
                              <th>
                                <strong>Document Name </strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {apidata?.map((transaction, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage - 1) * pageSize + index + 1}
                                  </td>
                                  <td>{transaction.receiverName}</td>
                                  <td>{transaction.tokenId}</td>
                                  <td>
                                    {transaction.authorizedData[0] == "P"
                                      ? "******" +
                                        (userData?.documents?.pan?.number
                                          ? userData.documents.pan.number.slice(
                                              -4
                                            )
                                          : "")
                                      : transaction.authorizedData[0] == "A"
                                      ? "******" +
                                        (userData?.documents?.aadhar?.number
                                          ? userData?.documents?.aadhar?.number.slice(
                                              -4
                                            )
                                          : "")
                                      : transaction.authorizedData[0] == "D"
                                      ? "******" +
                                        (userData?.documents?.drivingLicense
                                          ?.number
                                          ? userData?.documents?.drivingLicense?.number.slice(
                                              -4
                                            )
                                          : "")
                                      : transaction.authorizedData[0] == "V"
                                      ? "******" +
                                        (userData?.documents?.voterId?.number
                                          ? userData?.documents?.voterId?.number.slice(
                                              -4
                                            )
                                          : "")
                                      : transaction.authorizedData[0] == "PP"
                                      ? "******" +
                                        (userData?.documents?.passport?.number
                                          ? userData.documents.passport.number.slice(
                                              -4
                                            )
                                          : "")
                                      : transaction.authorizedData.join(", ")}
                                  </td>

                                  <td>
                                    {moment(transaction.createdAt).format(
                                      "lll"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage} of {totalPages}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="profile" title="Receive">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title
                          style={{ color: "white", margin: "auto" }}
                        ></Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>

                              <th>
                                <strong>From</strong>
                              </th>
                              <th>
                                <strong>Tokens ID</strong>
                              </th>
                              <th>
                                <strong>Document Name </strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userData1?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage1 - 1) * pageSize + index + 1}
                                  </td>
                                  <td>{data.receiverName}</td>
                                  <td>{data.tokenId}</td>
                                  <td>
                                    ****
                                    {atob(data.identificationNumber).slice(-4)}
                                  </td>

                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage1}
                              disabled={currentPage1 === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage1}
                              disabled={currentPage1 === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage1}
                              disabled={currentPage1 === totalPages1}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage1}
                              disabled={currentPage1 === totalPages1}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage1} of {totalPages1}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="longer-tab" title="Login Info">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>

                              <th>
                                <strong>Device ID</strong>
                              </th>
                              <th>
                                <strong>Device Type</strong>
                              </th>
                              <th>
                                <strong>Device Name </strong>
                              </th>
                              <th>
                                <strong>Device OS </strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userData2?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage2 - 1) * pageSize + index + 1}
                                  </td>

                                  <td>{data.deviceId}</td>
                                  <td>{data.deviceType}</td>
                                  <td>{data.deviceName}</td>
                                  <td>{data.deviceOS}</td>
                                  <td>
                                    {moment(data.loginTime).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage2}
                              disabled={currentPage2 === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage2}
                              disabled={currentPage2 === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage2}
                              disabled={currentPage2 === totalPages2}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage2}
                              disabled={currentPage2 === totalPages2}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage2} of {totalPages2}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="wallet-tab" title="Wallet">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>
                              <th>
                                <strong> Amount</strong>
                              </th>
                              <th>
                                <strong>Transaction ID </strong>
                              </th>
                              <th>
                                <strong>Transaction Type </strong>
                              </th>
                              <th>
                                <strong>Status</strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {wallet?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage4 - 1) * pageSize + index + 1}
                                  </td>
                                  <td>{data.amount}</td>
                                  <td>{data.txn_id}</td>
                                  <td>{data.txn_flow_type}</td>
                                  <td>{data.status}</td>
                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage4}
                              disabled={currentPage4 === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage4}
                              disabled={currentPage4 === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage4}
                              disabled={currentPage4 === totalPages4}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage3}
                              disabled={currentPage4 === totalPages4}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage4} of {totalPages4}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="third-party-tab" title="Third Party Login">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>
                              <th>
                                <strong>Business Name</strong>
                              </th>
                              <th>
                                <strong>Token ID </strong>
                              </th>
                              <th>
                                <strong>Device ID</strong>
                              </th>
                              <th>
                                <strong>Device Type</strong>
                              </th>
                              <th>
                                <strong>Device Name </strong>
                              </th>
                              <th>
                                <strong>Device OS </strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {apidata1?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage3 - 1) * pageSize + index + 1}
                                  </td>
                                  <td>{data.business}</td>
                                  <td>{data.tokenId}</td>
                                  <td>{data.deviceName}</td>
                                  <td></td>
                                  <td></td>
                                  <td>{data.deviceOS}</td>
                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage3}
                              disabled={currentPage3 === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage3}
                              disabled={currentPage3 === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage3}
                              disabled={currentPage3 === totalPages3}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage3}
                              disabled={currentPage3 === totalPages3}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage3} of {totalPages3}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="third-kyc" title="Third Party KYC ">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO.</strong>
                              </th>
                              <th>
                                <strong>Business Name</strong>
                              </th>
                              <th>
                                <strong>Token ID </strong>
                              </th>
                              <th>
                                <strong>Device ID</strong>
                              </th>
                              <th>
                                <strong>Device Type</strong>
                              </th>
                              <th>
                                <strong>Device Name </strong>
                              </th>
                              <th>
                                <strong>Device OS </strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {apidata1?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {(currentPage3 - 1) * pageSize + index + 1}
                                  </td>
                                  <td>{data.business}</td>
                                  <td>{data.tokenId}</td>
                                  <td>{data.deviceName}</td>
                                  <td></td>
                                  <td></td>
                                  <td>{data.deviceOS}</td>
                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody> 
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                        <div
                          className="text-center mb-3 col-lg-6"
                          style={{ margin: "auto" }}
                        >
                          <div className="filter-pagination  mt-3">
                            <button
                              className="previous-button"
                              onClick={handlePreviousPage3}
                              disabled={currentPage3 === 1}
                            >
                              {"<<"}
                            </button>

                            <button
                              className="previous-button"
                              onClick={handlePreviousPage3}
                              disabled={currentPage3 === 1}
                            >
                              Previous
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage3}
                              disabled={currentPage3 === totalPages3}
                            >
                              Next
                            </button>

                            <button
                              className="next-button"
                              onClick={handleNextPage3}
                              disabled={currentPage3 === totalPages3}
                            >
                              {">>"}
                            </button>

                            <span>
                              Page {currentPage3} of {totalPages3}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="addresh" title="Address ">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO</strong>
                              </th>
                              <th>
                                <strong>Addresh</strong>
                              </th>
                              <th>
                                <strong>Sender Name</strong>
                              </th>
                              <th>
                                <strong>Date&Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {addresh?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.full_address}</td>
                                  <td>{data.name}</td>

                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="bankandupi" title="Bank&UPI">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Form
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Form.Check
                            type="switch"
                            label="UPI ID"
                            onChange={handleSwitchChange}
                          />
                        </Form>
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                        <div>
                      <button
                        type="button"
                        className="btn outline_btn mr-10"
                        style={{ position: "relative" }}
                      >
                        <span >
                          <i
                            class="fa-solid fa-sliders"
                            style={{ fontSize: "unset", paddingRight: "8px" }}
                          ></i>
                          Filters
                        </span>
                        <div
                          className={`dropdown1 border  ` }
                        >
                          </div>
                          </button>
                          </div>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        {!showUPI && (
                          <Table
                            responsive
                            style={{
                              background: "white",
                              color: "black",
                              borderBottom: "1px solid white",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <strong>NO.</strong>
                                </th>
                                <th>
                                  <strong>Account Number</strong>
                                </th>
                                <th>
                                  <strong> Name</strong>
                                </th>
                                <th>
                                  <strong> Bank Name</strong>
                                </th>
                                <th>
                                  <strong>Branch</strong>
                                </th>
                                <th>
                                  <strong>IFSC Code</strong>
                                </th>
                                <th>
                                  <strong>Date&Time</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {bank?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.accountNumber}</td>
                                    <td>{data.accountHolderName}</td>
                                    <td>{data.bankName}</td>
                                    <td>{data.branch}</td>
                                    <td>{data.ifsc}</td>
                                    <td>
                                      {moment(data.createdAt).format("lll")}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                        {showUPI && (
                          <Table
                            responsive
                            style={{
                              background: "white",
                              color: "black",
                              borderBottom: "1px solid white",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <strong>NO</strong>
                                </th>
                                <th>
                                  <strong>UPI ID</strong>
                                </th>
                                <th>
                                  <strong>Date&Time</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {upi?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.upi_id}</td>
                                    <td>
                                      {moment(data.createdAt).format("lll")}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                        <div className="d-flex justify-content-between"></div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="Business " title="Business">
                <Row>
                  <Col lg={12}>
                    <Card>
                      <Card.Header
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Card.Title style={{ color: "white", margin: "auto" }}>
                          Top 10 Team{" "}
                        </Card.Title>
                      </Card.Header>
                      <Card.Body
                        style={{
                          background: "white",
                          border: "1px solid white",
                        }}
                      >
                        <Table
                          responsive
                          style={{
                            background: "white",
                            color: "black",
                            borderBottom: "1px solid white",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>
                                <strong>NO</strong>
                              </th>
                              <th>
                                <strong>Business Name</strong>
                              </th>
                              <th>
                                <strong>GST</strong>
                              </th>
                              <th>
                                <strong>PAN </strong>
                              </th>

                              <th>
                                <strong>Date & Time</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {business?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.businessName}</td>
                                  <td>
                                    {data?.documents?.gst?.number
                                      ? atob(data?.documents?.gst?.number)
                                      : ""}
                                  </td>

                                  <td>
                                    {data?.documents?.pan?.number
                                      ? atob(data?.documents?.pan?.number)
                                      : ""}
                                  </td>

                                  <td>
                                    {moment(data.createdAt).format("lll")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-between"></div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab>
            </Tabs> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
