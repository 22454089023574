import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../../services/api_function";

import { Row, Col, Card, Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function Adminaccount() {
  const [apiData, setApidata] = useState([]);
  const [api, setApi] = useState([]);
  const [coinPrice, setCoinPrice] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [upidUsers, setupiUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/usdt-wallet");
        setApidata(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    fetchInr();
    fetchPrice();
  }, []);

  const fetchInr = async () => {
    try {
      const response = await axios.get(url + "/admin-inr");
      setApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrice = async () => {
    try {
      const userDetails = localStorage.getItem("userDetails");
      const parsedDetails = JSON.parse(userDetails);
      const token = parsedDetails.token;

      const response = await axios.get(url + "/tg-price", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCoinPrice(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (event, adminAccount) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedUsers(...selectedUsers, adminAccount);
      sendDataToApi(adminAccount);
    } else {
      const updatedSelectedUsers = selectedUsers.filter(
        (user) => user !== adminAccount
      );
      setSelectedUsers(updatedSelectedUsers);
    }
  };
  const sendDataToApi = async (adminAccount) => {
    try {
      const response = await axios.post(url + "/admin-usdt", {
        adminAccount: adminAccount,
      });
      NotificationManager.success("Update successfully:", response.message);
      const apiResponse = await axios.get(url + "/usdt-wallet");
      setApidata(apiResponse.data.data);
    } catch (error) {
      NotificationManager.error("Error sending data:", error);
    }
  };

  const handleCheckboxInr = (event, upi) => {
    const isChecked = event.target.checked;
    setLoading(true);
    if (isChecked) {
      setupiUsers(...upidUsers, upi);
      sendInrApi(upi);
    } else {
      const updatedSelectedUsers = upidUsers.filter((user) => user !== upi);
      setSelectedUsers(updatedSelectedUsers);
    }
    setLoading(false);
  };
  const sendInrApi = async (upi) => {
    try {
      const response = await axios.post(url + "/admin-upi", { upi });
      NotificationManager.success(response.data.message);
      const apiResponse = await axios.get(url + "/admin-inr");
      setApi(apiResponse.data.data);
    } catch (error) {
      NotificationManager.error("Error sending data:", error.message);
    }
  };

  const handleDeleteUpi = (upiId, action) => {
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;

    axios
      .post(
        url + "/account-delete",
        { upiId, action: "UPI" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "User deleted successfully");
        NotificationManager.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        NotificationManager.error("Error sending data:", error.message);
      });
  };

  const handleDeletewallet = (wallet) => {
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;

    axios
      .post(
        url + "/account-delete",
        { wallet, wallet: "Wallet" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "User deleted successfully");
        NotificationManager.success(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        NotificationManager.error("Error sending data:", error.message);
      });
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={6}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                USDT
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong></strong>
                    </th>
                    <th>
                      <strong>Addresh</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          checked={user.active == true}
                          onChange={(event) =>
                            handleCheckboxChange(event, user.accountAddress)
                          }
                        />
                      </td>
                      <td>{user.accountAddress}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-trash"
                          onClick={() =>
                            handleDeletewallet(user.accountAddress)
                          }
                        >
                          <i
                            className="bi bi-trash"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <div className="container mt-2">
              <h1 style={{ fontSize: "20px", whiteSpace: "nowrap" }}>
                Contract address: 0xf565F02773839c5F9CD6e11BD474db6b18f01F14
              </h1>
            </div>

            <div className="container mt-2">
              <h1 style={{ textAlign: "center" }}>
                TG Coin Price: {coinPrice.price ?? 0}
              </h1>
            </div>
          </Card>
        </Col>

        <Col lg={6}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                INR
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <tbody>
                  {api.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={user.active == true}
                          onChange={(event) =>
                            handleCheckboxInr(event, user.upi)
                          }
                        />
                      </td>
                      <td>
                        <div>
                          <img src={user?.url} alt="User Image" width="100" />
                        </div>
                      </td>
                      <td>{user.upi}</td>

                      <td>
                        <button
                          type="button"
                          class="btn btn-trash"
                          onClick={() => handleDeleteUpi(user.upi)}
                        >
                          <i
                            className="bi bi-trash"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Adminaccount;
