import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../../services/api_function";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
function ImageUploadPage() {
  const [image, setImage] = useState(null);
  const [upi, setUpi] = useState("");
  const [wallet, setWallet] = useState("");
  const [apiData, setApidata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [tgcoin, settgcoin] = useState("");
  const [userId, setuserId] = useState("");

  const userDetails = localStorage.getItem("userDetails");
  const parsedDetails = JSON.parse(userDetails);
  const token = parsedDetails.token;

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };
  const handleUpiChange = (event) => {
    setUpi(event.target.value);
  };

  const handleImageUpload = async () => {
    setIsLoading(true);
    try {
      if (!image || !upi) {
        alert("Please fill both fields");
        return;
      }

      const formData = new FormData();
      formData.append("image", image);
      formData.append("adminUpi", upi);
      const response = await axios.post(url + "/upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status == 200) {
        NotificationManager.success("Upload successful.:", response.message);
        setImage(null);
        setUpi("");
      } else {
        NotificationManager.error("Upload failed. Status:", response.status);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    setIsLoading(false);
  };

  const handelWalletchnage = async (event) => {
    console.log(event.target.value);
    try {
      setWallet(event.target.value);
    } catch {}
  };

  const handelpriceChnage = async (event) => {
    console.log(event.target.value);
    try {
      settgcoin(event.target.value);
    } catch {}
  };

  const clearwallet = () => {
    setWallet("");
  };

  const handelsubmit = async () => {
    setIsLoading1(true);
    try {
      const formData = { wallet };
      const response = await axios.post(url + "/contract", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        NotificationManager.success("Upload successful.:", response.message);
        clearwallet();
      } else {
        NotificationManager.error("Upload failed. Status:", response.status);
      }
    } catch (error) {
      NotificationManager.error("Error uploading image:", error);
    }
    setIsLoading1(false);
  };
  const email = "nmshinu529628@gmail.com";
  const handelupdate = async () => {
    setIsLoading1(true);
    try {
      const formData = { price: tgcoin, email };
      const response = await axios.post(url + "/price-update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status == 200) {
        NotificationManager.success(response.data.message);
        settgcoin("");
      } else {
        NotificationManager.error(response.data.message);
      }
    } catch (error) {
      NotificationManager.error("Error uploading image:", error);
    }
    setIsLoading1(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/usdt-wallet");
        setApidata(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      <div>
        <div className="container mt-2">
          <h1>Add INR Account</h1>
          <div className="mb-3">
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="UPI"
              onChange={handleUpiChange}
            />
          </div>
          <button className="btn btn-primary" onClick={handleImageUpload}>
            {isLoading ? "Uploading..." : "Upload Image"}
          </button>
        </div>

        <div className="container mt-2">
          <h1>Add USDT Wallet</h1>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="USDT Wallet"
              onChange={handelWalletchnage}
            />
          </div>
          <button className="btn btn-primary" onClick={handelsubmit}>
            {isLoading1 ? "Uploading..." : "Add Wallet"}
          </button>
        </div>
        <div className="container mt-2">
          <h1>Update TG Coin Price</h1>
          <div className="mb-2 d-flex align-items-center">
            <input
              type="number"
              className="form-control w-auto"
              style={{ marginRight: "20px" }}
              placeholder="Tg Price"
              required
              onChange={handelpriceChnage}
            />

            <button className="btn btn-primary" onClick={handelupdate}>
              {isLoading1 ? "Uploading..." : "Update Price"}
            </button>
          </div>
        </div>
        {/* <div className="container mt-2">
          <h1>Block User</h1>
          <div className="mb-2 d-flex align-items-center">
            <input
              type="text"
              style={{ marginRight: "20px" }}
              className="form-control w-auto"
              placeholder="User ID"
              required
            />
            <button
              className="btn btn-danger mr-1"
              style={{ marginRight: "20px" }}
            >
              Block
            </button>
            <button
              className="btn btn-success mr-2"
              style={{ marginRight: "20px" }}
            >
              Unblock
            </button>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
}

export default ImageUploadPage;
