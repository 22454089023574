import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { url } from "../../../services/api_function";
import { NotificationManager } from "react-notifications";
const Announcement = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDetails = localStorage.getItem("userDetails");
    const parsedDetails = JSON.parse(userDetails);
    const token = parsedDetails.token;
    try {
      const response = await fetch(url+"/announcement", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ title, message }),
      });

      if(response.status == 200){
        NotificationManager.success("Upload successful.:", response.message);
      }else{
        NotificationManager.error("Upload failed:", response.status);
      }

      setTitle("");
      setMessage("");
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };
  return (
    <Fragment>
      <div className="col-xl-12 col-lg-6 mt-5">
        <div className="card">
          <h4 className="card-title text-center mt-5">Title</h4>
          <div className="card-header">
            <textarea
              className="form-txtarea form-control"
              rows="1"
              id="comment"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></textarea>
          </div>

          <div className="card-body">
            <div className="basic-form">
              <h4 className="card-title text-center">Message</h4>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <textarea
                    className="form-txtarea form-control"
                    rows="4"
                    id="comment"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <Button
                  type="submit"
                  className="me-2 d-flex justify-content-center"
                  variant="success"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Announcement;
